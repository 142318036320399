import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-grid-system";

const Wrapper = styled.div`
  padding-bottom: 100px;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    p {
      font-size: 1.9rem;
      line-height: 3rem;
    }
  }

  padding-bottom: 75px;
`;

const Img = styled.div`
  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    width: 83.3333%;
    padding-left: 16.6667%;
  }
`;

const Headline = styled.div`
  h1,
  p {
    font-family: "Harbour-Medium";
    color: #000;
    font-size: 4.3rem;
    line-height: 3.4rem;
  }

  padding-bottom: 50px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    p {
      font-size: 3.3rem;
      line-height: 2.6rem;
    }
    padding-bottom: 25px;
  }
`;

const Subline = styled.div`
  p {
    font-family: "MaisonNeue";
    font-size: 2.6rem;
    line-height: 4.2rem;
    color: #000;
    margin: 0;
    padding: 0;
    letter-spacing: -0.1rem;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    p {
      font-size: 1.9rem;
      line-height: 3rem;
    }
  }
`;

const SubWrapper = styled.div`
  padding-top: 50px;

  p {
    font-family: "MaisonNeue";
    font-size: 1.9rem;
    line-height: 3.3rem;
    color: #000;
    margin: 0;
    padding: 0;
    letter-spacing: -0.1rem;
  }
`;

const Shop = styled.div`
  margin-top: 50px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
`;

const ShopInfos = styled.div`
  strong {
    color: #ba8f5c;
  }
`;

const Map = styled.div`
  border: 1px solid rgba(0, 0, 0, 0);
  width: 100%;
  height: 558px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
`;

const Space = styled.div`
  height: 100px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    height: 75px;
  }
`;

class Contact extends React.Component {
  render() {
    const headline = this.props.data.headline.content;

    const copytext = this.props.data.copytext.content;
    const extratext = this.props.data.extratext.content;
    const image = process.env.REACT_APP_IMG_URL + this.props.data.image[0].url;
    const shops = this.props.data.shops;

    const shopmapurl = shops[0].shopmapurl;

    const shopimage =
      process.env.REACT_APP_IMG_URL + shops[0].shopmapimg[0].url;

    return (
      <Wrapper id="laeden">
        <Headline>
          <h1 dangerouslySetInnerHTML={{ __html: headline }} />
        </Headline>

        <Subline>
          <p dangerouslySetInnerHTML={{ __html: copytext }} />
        </Subline>
        <SubWrapper>
          <Container fluid>
            <Row>
              <Col sm={12} md={12} lg={6} xl={6}>
                <Col sm={12} md={12} lg={12} xl={12}>
                  <a href={shopmapurl} target="_blank">
                    <Map>
                      <img src={shopimage} alt="GRAGGER" />
                    </Map>
                  </a>
                  <Space />
                </Col>
                <Col offset={{ lg: 2, xl: 2 }} sm={12} md={12} lg={10} xl={10}>
                  <p dangerouslySetInnerHTML={{ __html: extratext }} />
                </Col>
              </Col>

              <Col sm={12} md={12} lg={1} xl={1}></Col>

              <Col sm={12} md={12} lg={5} xl={5}>
                <Space />
                <ShopInfos>
                  <p>
                    <strong>{shops[0].shopname}</strong>
                  </p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: shops[0].shopadress.content,
                    }}
                  />
                </ShopInfos>
                <Space />
                <Img>
                  <img src={image} alt="GRAGGER"></img>
                </Img>
              </Col>
            </Row>
          </Container>
        </SubWrapper>
      </Wrapper>
    );
  }
}

export default Contact;

/*


        {shops &&
          shops.map((list, index) => (
            <Shop key={index}>
              <Map></Map>
              <ShopInfos>
                <h2>{list.shopname}</h2>
                <p dangerouslySetInnerHTML={{ __html: list.shopadress }} />
              </ShopInfos>
            </Shop>
          ))}


          */
