import React from "react";
import styled from "styled-components";

import Fade from "react-reveal/Fade";
const Wrapper = styled.div`
  padding-bottom: 100px;
  margin-top: 50px;
  margin-bottom: 50px;
  position: relative;

  h1,
  p {
    font-family: "Harbour-Medium";
    color: #000;
    font-size: 21rem;
    line-height: 23rem;
    text-align: left;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    h1,
    p {
      font-size: 6.2rem;
      line-height: 7.8rem;
    }
    padding-bottom: 75px;
  }
`;

const Copy = styled.div`
  position: absolute;
  top: 0px;
`;

const CopyEn = styled.div`
  position: absolute;
  top: 0px;

  p {
    color: #000;
    font-size: 16rem;
    line-height: 18rem;
  }
`;

const Img = styled.div`
  padding-left: 330px;
  padding-top: 390px;

  width: 500px;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    width: 75%;
    padding-left: 25%;
    padding-top: 130px;
  }
`;

const ImgEn = styled.div`
  padding-left: 330px;
  padding-top: 270px;

  width: 500px;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    width: 75%;
    padding-left: 25%;
    padding-top: 130px;
  }
`;

class SubHero extends React.Component {
  render() {
    const copytext = this.props.data.headline.content;
    const lang = this.props.lang;

    const image = process.env.REACT_APP_IMG_URL + this.props.data.image[0].url;

    return (
      <Fade bottom distance={"100px"} duration={600} delay={300}>
        <Wrapper>
          {lang === "de" ? (
            <>
              <Img>
                <img src={image} alt="GRAGGER"></img>
              </Img>
              <Copy>
                <h1 dangerouslySetInnerHTML={{ __html: copytext }} />
              </Copy>
            </>
          ) : (
            <>
              <ImgEn>
                <img src={image} alt="GRAGGER"></img>
              </ImgEn>
              <CopyEn>
                <h1 dangerouslySetInnerHTML={{ __html: copytext }} />
              </CopyEn>
            </>
          )}
        </Wrapper>
      </Fade>
    );
  }
}

export default SubHero;
