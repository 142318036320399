import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";

import Fade from "react-reveal/Fade";

import { gsap, Power3 } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";

/* eslint-disable import/first */
gsap.registerPlugin(ScrollToPlugin);

import Hero from "../Hero";
import SubHero from "../SubHero";
import Contact from "../Contact";
import Header from "../Header";
import Social from "../Social";
import Footer from "../Footer";
import BackTop from "../BackTop";
import CopyBlock from "../CopyBlock";
import ImageBlock from "../ImageBlock";
import ContentBlock from "../ContentBlock";

import Imprint from "../Imprint";
import Privacy from "../Privacy";

import ScrollToTop from "../utils/ScrollToTop";

import AutoScroll from "../utils/AutoScroll";

const Wrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`;

const Base = styled.div`
  padding-left: 90px;
  padding-right: 90px;
  padding-top: 115px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const Space = styled.div`
  height: 100px;
  @media (max-width: ${(props) => props.theme.mobilesize}) {
    height: 75px;
  }

  &.check {
    @media (max-width: ${(props) => props.theme.mobilesize}) {
      height: 0px;
    }
  }
`;

const Theme = {
  mobilesize: "992px",
};

class Homepage extends React.Component {
  handleClick = (id) => {
    this.scrollFx(id);
  };

  langClick = (id) => {
    this.props.onLangClick(id);
  };

  scrollFx = (id) => {
    const target = "#" + id;
    gsap.to(window, {
      duration: 2,
      ease: Power3.easeOut,
      scrollTo: { y: target, offsetY: 170 },
    });
  };

  render() {
    const data = this.props.data;

    const dat = data.entries[0].components;
    const dataHero = dat.find((item) => item.title === "hero");
    const dataSection1 = dat.find((item) => item.title === "section-01");
    const dataSection2 = dat.find((item) => item.title === "section-02");
    const dataSection3 = dat.find((item) => item.title === "section-03");
    const dataSection4 = dat.find((item) => item.title === "section-04");
    const dataSection5 = dat.find((item) => item.title === "section-05");
    const dataSection6 = dat.find((item) => item.title === "section-06");
    const dataContact = dat.find((item) => item.title === "contact");
    const dataSection7 = dat.find((item) => item.title === "section-07");
    const dataSection8 = dat.find((item) => item.title === "section-08");

    const imprintData = dat.find((item) => item.title === "imprint");
    const privacyData = dat.find((item) => item.title === "privacy");

    let isMobile = true;
    let device = Device_Type();
    if (device === "Desktop") {
      isMobile = false;
    }

    // isMobile = true;
    // console.log("isMobile: ", isMobile);
    const lang = this.props.language;

    const Page = (
      <Switch>
        <Route exact path="/">
          <Base>
            <Hero data={dataHero} />
            <Container fluid>
              <Row>
                <Col sm={12} md={12} lg={2} xl={2}></Col>
                <Col sm={12} md={12} lg={9} xl={9}>
                  <CopyBlock data={dataSection1} />
                </Col>
              </Row>

              <Row>
                <Col
                  xs={10}
                  sm={10}
                  md={10}
                  offset={{ xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }}
                  lg={5}
                  xl={5}>
                  <ImageBlock data={dataSection2} />
                  <Space />
                </Col>
                <Col sm={12} md={12} lg={1} xl={1}></Col>
                <Col sm={12} md={12} lg={6} xl={6}>
                  <Space className="check" />
                  <CopyBlock data={dataSection2} />
                  <CopyBlock data={dataSection3} />
                </Col>
              </Row>

              <Row>
                <Col sm={12} md={12} lg={1} xl={1}></Col>
                <Col sm={12} md={12} lg={10} xl={10}>
                  <SubHero data={dataSection4} lang={lang} />
                </Col>
                <Col sm={12} md={12} lg={1} xl={1}></Col>
              </Row>
            </Container>
            <CopyBlock data={dataSection5} />
            <CopyBlock data={dataSection6} />
            <Row id="gragger">
              <Col
                xs={10}
                sm={10}
                md={10}
                offset={{ xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }}
                lg={5}
                xl={5}>
                <ImageBlock data={dataSection7} />
                <Space />
              </Col>
              <Col sm={12} md={12} lg={1} xl={1}></Col>
              <Col sm={12} md={12} lg={6} xl={6}>
                <Space className="check" />
                <CopyBlock data={dataSection7} />
              </Col>
            </Row>
            <Fade bottom distance={"100px"} duration={600} delay={300}>
              <Contact data={dataContact} />
            </Fade>
            <Space className="check" />
            <ContentBlock data={dataSection8} />
            <Space className="check" />
            <Footer lang={lang} />
          </Base>
        </Route>

        <Route path="/impressum">
          <Base>
            <Imprint lang={lang} data={imprintData} />
            <Footer lang={lang} />
          </Base>
        </Route>

        <Route path="/datenschutz">
          <Base>
            <Privacy lang={lang} data={privacyData} />
            <Footer lang={lang} />
          </Base>
        </Route>
      </Switch>
    );

    return (
      <Router>
        <ThemeProvider theme={Theme}>
          <Wrapper>
            {/*
            {isMobile === true ? (
              <>
                <ScrollToTop />
                {Page}
              </>
            ) : (
              <AutoScroll>{Page}</AutoScroll>
            )}
            */}

            {isMobile === true ? (
              <>
                <ScrollToTop />
                {Page}
              </>
            ) : (
              <AutoScroll>{Page}</AutoScroll>
            )}

            <Header
              lang={lang}
              onButtonClick={this.handleClick.bind(this)}
              onLangClick={this.langClick.bind(this)}
            />
            <Social lang={lang} />
            <BackTop lang={lang} onButtonClick={this.handleClick.bind(this)} />
          </Wrapper>
        </ThemeProvider>
      </Router>
    );
  }
}

export default Homepage;

function Device_Type() {
  var Return_Device;
  if (
    /(up.browser|up.link|mmp|symbian|smartphone|midp|wap|phone|android|iemobile|w3c|acs\-|alav|alca|amoi|audi|avan|benq|bird|blac|blaz|brew|cell|cldc|cmd\-|dang|doco|eric|hipt|inno|ipaq|java|jigs|kddi|keji|leno|lg\-c|lg\-d|lg\-g|lge\-|maui|maxo|midp|mits|mmef|mobi|mot\-|moto|mwbp|nec\-|newt|noki|palm|pana|pant|phil|play|port|prox|qwap|sage|sams|sany|sch\-|sec\-|send|seri|sgh\-|shar|sie\-|siem|smal|smar|sony|sph\-|symb|t\-mo|teli|tim\-|tosh|tsm\-|upg1|upsi|vk\-v|voda|wap\-|wapa|wapi|wapp|wapr|webc|winw|winw|xda|xda\-) /i.test(
      navigator.userAgent
    )
  ) {
    if (
      /(tablet|ipad|playbook)|(android(?!.*(mobi|opera mini)))/i.test(
        navigator.userAgent
      )
    ) {
      Return_Device = "Tablet";
    } else {
      Return_Device = "Mobile";
    }
  } else if (
    /(tablet|ipad|playbook)|(android(?!.*(mobi|opera mini)))/i.test(
      navigator.userAgent
    )
  ) {
    Return_Device = "Tablet";
  } else {
    Return_Device = "Desktop";
  }

  return Return_Device;
}
