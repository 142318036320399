import React from "react";
import styled from "styled-components";

import Fade from "react-reveal/Fade";

const Wrapper = styled.div`
  padding-bottom: 100px;

  p {
    font-family: "MaisonNeue";
    font-size: 1.9rem;
    line-height: 2.8rem;
    color: #000;
    margin: 0;
    padding: 0;
    letter-spacing: -0.1rem;
  }

  strong {
    /* color: #ba8f5c; */
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    p {
      font-size: 1.9rem;
      line-height: 3rem;
    }
    padding-bottom: 75px;
  }
`;

const Headline = styled.div`
  h1,
  p {
    font-family: "Harbour-Medium";
    color: #000;
    font-size: 4.3rem;
    line-height: 3.4rem;
  }
  padding-bottom: 50px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    p {
      font-size: 3.3rem;
      line-height: 2.6rem;
    }
    padding-bottom: 25px;
  }
`;
class ContentBlock extends React.Component {
  render() {
    const headline = this.props.data.headline.content;
    const copytext = this.props.data.copytext.content;

    return (
      <Fade bottom distance={"100px"} duration={600} delay={300}>
        <Wrapper>
          <Headline>
            <h1 dangerouslySetInnerHTML={{ __html: headline }} />
          </Headline>
          <p dangerouslySetInnerHTML={{ __html: copytext }} />
        </Wrapper>
      </Fade>
    );
  }
}

export default ContentBlock;
