import React from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Menu from "../Menu";
import logo from "../../logo.svg";

const Wrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 68px;
`;

const Background = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 100px;
  background: #fff;

  &.show {
    pointer-events: none;
    animation: fadeout ease-in 0.6s 1 normal forwards;
  }

  &.hide {
    pointer-events: auto;
    animation: fadein ease-out 0.6s 1 normal forwards;
  }

  @keyframes fadeout {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

const Content = styled.div`
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  padding-left: 90px;
  padding-right: 90px;

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    padding-left: 30px;
    padding-right: 30px;
  }

  display: flex;
  justify-content: space-between;

  ul {
    bottom: 0px;
    padding: 0px;
    line-height: 15px;
    padding-top: 40px;
    li {
      display: inline-block;
      font-family: "MaisonNeue";
      font-size: 1.6rem;
      color: #000;
      padding: 0px;
      cursor: pointer;

      :hover {
        color: #ba8f5c;
      }

      &.center {
        padding-left: 30px;
        padding-right: 30px;
      }

      &.active {
        color: #ba8f5c;
      }
    }
  }
`;

const Logo = styled.div`
  position: absolute;
  left: 50%;

  z-index: 1;
  height: 45px;
  padding-top: 22px;

  img {
    margin-left: -50%;
    width: auto;
    height: 100%;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    height: 28px;
    padding-top: 25px;
  }
`;

const Navi = styled.div`
  ul {
    display: block;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    ul {
      display: none;
    }
  }
`;

const Lang = styled.div`
  ul {
    display: block;
  }

  @media (max-width: ${(props) => props.theme.mobilesize}) {
    ul {
      display: none;
    }
  }
`;

class Header extends React.Component {
  state = {
    language: "de",
    isOpen: true,
  };

  buttonClick = (id) => {
    this.props.onButtonClick(id);
  };

  toggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  languageClick = (lang) => {
    this.props.onLangClick(lang);
  };

  render() {
    const lang = this.props.lang;

    let de = "deutsch";
    let en = "englisch";

    let linkA = "Brot";
    let linkB = "Gragger";
    let linkC = "Läden";

    if (lang === "en") {
      de = "german";
      en = "english";

      linkA = "Bread";
      linkB = "Gragger";
      linkC = "Shops";
    }

    return (
      <Wrapper>
        <Background
          className={` ${this.state.isOpen === true ? "show" : "hide"}`}
        />
        <Content>
          <Lang>
            <ul>
              <li
                onClick={this.languageClick.bind(this, "de")}
                className={` ${lang === "de" ? "active" : ""}`}>
                {de}
              </li>
              <li
                onClick={this.languageClick.bind(this, "en")}
                className={`center ${lang === "en" ? "active" : ""}`}>
                {en}
              </li>
            </ul>
          </Lang>
          <Link to="/">
            <Logo>
              <img src={logo} alt="GRAGGER" />
            </Logo>
          </Link>
          <Navi>
            <ul>
              <li onClick={this.buttonClick.bind(this, "brot")}>{linkA}</li>
              <li
                onClick={this.buttonClick.bind(this, "gragger")}
                className="center">
                {linkB}
              </li>
              <li onClick={this.buttonClick.bind(this, "laeden")}>{linkC}</li>
            </ul>
          </Navi>
        </Content>
        <Menu
          language={lang}
          onOpen={this.toggleOpen.bind(this)}
          onClick={this.buttonClick.bind(this)}
          onLangClick={this.languageClick.bind(this)}
        />
      </Wrapper>
    );
  }
}

export default Header;
